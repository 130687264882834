import * as React from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import { FieldTitle, useInput } from 'ra-core'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import DayTimeInputItem from 'src/components/inputs/WorkScheduleInput/DayTimeInputItem'
import styles from './index.module.scss'
import { InputProps } from 'react-admin'
import Checkbox from '@mui/material/Checkbox'
import { Input } from '@mui/material'
import { Hours24, Plus, CloseCircleOutline, ContentDuplicate } from 'mdi-material-ui'
import IconButton from '@mui/material/IconButton'

type ValueObject = {
  [key: string | number]: Array<{
    id?: number | string
    from?: string | null
    to?: string | null
    limit?: number | null
  }>
}

interface ScheduleValue {
  id: string
  name: string
}

interface Props extends InputProps<any> {
  schedule?: ScheduleValue[]
  row?: boolean
  withLimit?: boolean
}

const WorkScheduleMultiTimeInput = (props: Props) => {
  const { isRequired, field, fieldState } = useInput<ValueObject>(props)
  //
  const value = field.value
  const schedule = props.schedule ?? [
    { id: 1, name: 'Понедельник' },
    { id: 2, name: 'Вторник' },
    { id: 3, name: 'Среда' },
    { id: 4, name: 'Четверг' },
    { id: 5, name: 'Пятница' },
    { id: 6, name: 'Суббота' },
    { id: 0, name: 'Воскресенье' },
  ]
  //
  const isAllEnabled = Object.keys(value ?? {}).length === schedule.length
  const isAll24 =
    isAllEnabled &&
    Object.keys(value ?? {}).every(
      (rowId) => value[rowId] && value[rowId]['from'] === '00:00' && value[rowId]['to'] === '00:00',
    )

  const handleChangeType = (value: string) => {
    const [rowId, val] = value.split('||') as string[]
    const newValue = { ...field.value }
    if (!newValue[rowId]) {
      newValue[rowId] = {}
    }
    newValue[rowId]['dayType'] = val
    field.onChange(newValue)
    field.onBlur()
    // finalFormOnBlur();
  }

  const handleChangeTime = (
    fieldValue: string | null,
    rowId: string | number,
    index: string | number,
    type: string,
  ) => {
    const newValue = { ...field.value }

    if (!newValue[rowId]) {
      newValue[rowId] = []
    }
    if (!newValue[rowId][index]) {
      newValue[rowId][index] = {}
    }
    newValue[rowId][index][type] = fieldValue
    newValue[rowId][index]['allDay'] = newValue[rowId][index].from === '00:00' && newValue[rowId][index].to === '00:00'
    //
    field.onChange(newValue)
    field.onBlur()
  }

  const handleChangeLimit = (fieldValue: number, rowId: string | number, index: string | number) => {
    const newValue = { ...field.value }
    let newFieldValue = fieldValue

    if (!newValue[rowId]) {
      newValue[rowId] = []
    }
    if (!newValue[rowId][index]) {
      newValue[rowId][index] = {}
    }
    if (!_.isNumber(newFieldValue) || newFieldValue < 0) {
      newFieldValue = 0
    }
    newValue[rowId][index]['limit'] = newFieldValue
    //
    field.onChange(newValue)
    field.onBlur()
  }

  const handleEnableDay = (checked: boolean, rowId: string | number) => {
    if (checked) {
      const keys = Object.keys(field.value)
      const otherValues = keys.map((i) => field.value[i]).filter((i) => i.from && i.to)[0]

      const newValue = { ...field.value }
      newValue[rowId] = [{ from: otherValues?.from ?? null, to: otherValues?.to ?? null }]
      //
      field.onChange(newValue)
    } else {
      const newValue = { ...field.value }
      delete newValue[rowId]
      //
      field.onChange(newValue)
    }
    field.onBlur()
  }

  const handleCopyToNext = (rowId: string | number) => {
    if (rowId >= 6) {
      return
    }
    const key = (rowId as number) + 1
    const newValue = { ...field.value, ...{ [key]: [...field.value[rowId]] } }

    field.onChange(newValue)
    field.onBlur()
  }

  const handleEnable24 = (checked: boolean, rowId: string | number) => {
    if (checked) {
      const keys = Object.keys(field.value)
      const newValue = { ...field.value }
      //
      newValue[rowId] = [{ from: '00:00', to: '00:00' }]
      field.onChange(newValue)
    } else {
      const newValue = { ...field.value }
      newValue[rowId] = [...newValue[rowId], { ...newValue[rowId][0], to: null }]
      //
      field.onChange(newValue)
    }
    field.onBlur()
  }

  const handleAll24 = (checked: boolean) => {
    const newValue: ValueObject = {}
    if (checked) {
      for (const item of schedule) {
        newValue[item.id] = [{ from: '00:00', to: '00:00' }]
      }
    } else {
      for (const item of schedule) {
        newValue[item.id] = [{ from: '00:00', to: null }]
      }
    }
    field.onChange(newValue)
    field.onBlur()
  }

  const handleAllClick = (checked: boolean) => {
    const newValue: any = {}

    if (checked) {
      for (const item of schedule) {
        if (!value[item.id]) {
          newValue[item.id] = value[item.id] ? value[item.id] : [{ from: null, to: null }]
        } else {
          newValue[item.id] = value[item.id]
        }
      }
    }
    field.onChange(newValue)
    field.onBlur()
  }

  const handleAddTime = (rowId: string | number) => {
    const keys = Object.keys(field.value)
    const newValue: ValueObject = { ...field.value }

    if (!newValue[rowId]) {
      newValue[rowId] = [{ from: null, to: null }]
    }
    newValue[rowId] = [...newValue[rowId], { from: null, to: null }]
    //
    field.onChange(newValue)
  }

  const handleRemoveTime = (rowId: string | number, index: number) => {
    const keys = Object.keys(field.value)
    const newValue: ValueObject = { ...field.value }
    //
    newValue[rowId].splice(index, 1)
    field.onChange(newValue)
  }
  //
  return (
    <FormControl
      component="fieldset"
      margin={'dense'}
      error={fieldState.isTouched && !!fieldState.error}
      disabled={props.disabled}
      fullWidth={false}
    >
      {props.label && (
        <FormLabel component="legend" className={styles.label}>
          <FieldTitle label={props.label} source={props.source} resource={props.resource} isRequired={isRequired} />
        </FormLabel>
      )}
      <FormHelperText sx={{ fontSize: 14, mb: 1 }}>
        {props.withLimit && 'Значение лимита 0 = неограниченное количество бронирований'}
      </FormHelperText>
      <FormGroup row={props.row}>
        <TableContainer component={Paper}>
          <Table aria-label="caption table" className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell size={'small'}>
                  <Checkbox
                    value={isAllEnabled}
                    checked={isAllEnabled}
                    disabled={props.disabled}
                    onChange={(e) => handleAllClick(e.target?.checked)}
                  />
                </TableCell>
                <TableCell size={'small'}>День</TableCell>
                <TableCell align="left" size={'small'}>
                  Время
                </TableCell>
                {props.withLimit && (
                  <TableCell align="left" size={'small'}>
                    Лимит
                  </TableCell>
                )}
                <TableCell align="right" size={'small'}>
                  <IconButton aria-label="panorama" disabled={props.disabled} onClick={() => handleAll24(!isAll24)}>
                    <Hours24 color={isAll24 ? 'primary' : 'disabled'} />
                  </IconButton>
                </TableCell>
                <TableCell align="right" size={'small'}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule.map(({ id: rowId, name: rowName }) => (
                <TableRow key={rowId}>
                  <TableCell size={'small'} scope="row">
                    <Checkbox
                      value={!!value && !!value[rowId]}
                      checked={!!value && !!value[rowId]}
                      disabled={props.disabled}
                      onChange={(e) => handleEnableDay(e.target?.checked, rowId)}
                    />
                  </TableCell>
                  <TableCell size={'small'} scope="row">
                    {rowName}
                  </TableCell>
                  <TableCell size={'small'} align="left">
                    {(field.value[rowId]?.length > 0 ? field.value[rowId] : [{ from: null, to: null }]).map(
                      (_time: { from?: string | null; to?: string | null }, index: number) => (
                        <div key={`${rowId}.${index}`} className={styles.timeContainer}>
                          <div className={styles.timeInput}>
                            <DayTimeInputItem
                              disabled={!value[rowId] || props.disabled}
                              key={`${rowId}.${index}.from`}
                              name={`${rowId}.${index}.from`}
                              onChange={(value) => handleChangeTime(value, rowId, index, 'from')}
                              value={
                                (value && value[rowId] && value[rowId][index] && value[rowId][index]['from']) || null
                              }
                            />
                          </div>
                          <div className={styles.timeSep}>-</div>
                          <div className={styles.timeInput}>
                            <DayTimeInputItem
                              disabled={!value[rowId] || props.disabled}
                              key={`${rowId}.${index}.to`}
                              name={`${rowId}.${index}.to`}
                              onChange={(value) => handleChangeTime(value, rowId, index, 'to')}
                              value={
                                (value && value[rowId] && value[rowId][index] && value[rowId][index]['to']) || null
                              }
                            />
                          </div>
                          {(field.value[rowId]?.length ?? 0) > 1 ? (
                            <IconButton
                              aria-label="panorama"
                              disabled={props.disabled}
                              onClick={() => handleRemoveTime(rowId, index)}
                            >
                              <CloseCircleOutline color={'primary'} />
                            </IconButton>
                          ) : (
                            <IconButton
                              disabled={!value[rowId] || props.disabled}
                              aria-label="panorama"
                              onClick={() => handleAddTime(rowId)}
                            >
                              <Plus color={'primary'} />
                            </IconButton>
                          )}
                        </div>
                      ),
                    )}

                    {(field.value[rowId]?.length ?? 0) > 1 && (
                      <IconButton aria-label="panorama" disabled={props.disabled} onClick={() => handleAddTime(rowId)}>
                        <Plus color={'primary'} />
                      </IconButton>
                    )}
                  </TableCell>
                  {props.withLimit && (
                    <TableCell size={'small'} align={'left'} valign={'top'} sx={{ verticalAlign: 'top' }}>
                      {(field.value[rowId]?.length > 0 ? field.value[rowId] : [{ from: null, to: null }]).map(
                        (_time: { from?: string | null; to?: string | null }, index: number) => (
                          <div className={styles.limitInput}>
                            <Input
                              disabled={!value[rowId] || props.disabled}
                              key={`${rowId}.${index}.limit`}
                              name={`${rowId}.${index}.limit`}
                              type={'number'}
                              onChange={(event) => handleChangeLimit(+event.target.value, rowId, index)}
                              value={
                                (value && value[rowId] && value[rowId][index] && value[rowId][index]['limit']) ?? 0
                              }
                            />
                          </div>
                        ),
                      )}
                    </TableCell>
                  )}
                  <TableCell size={'small'} align="right">
                    <IconButton
                      aria-label="panorama"
                      disabled={props.disabled}
                      onClick={() =>
                        handleEnable24(
                          !(
                            value &&
                            value[rowId] &&
                            value[rowId][0] &&
                            value[rowId][0]['from'] === '00:00' &&
                            value[rowId][0]['to'] === '00:00'
                          ),
                          rowId,
                        )
                      }
                    >
                      <Hours24
                        color={
                          value &&
                          value[rowId] &&
                          value[rowId][0] &&
                          value[rowId][0]['from'] === '00:00' &&
                          value[rowId][0]['to'] === '00:00'
                            ? 'primary'
                            : 'disabled'
                        }
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell size={'small'} align="right">
                    {rowId <= 6 && (
                      <IconButton
                        aria-label="panorama"
                        disabled={props.disabled}
                        onClick={() => handleCopyToNext(rowId)}
                      >
                        <ContentDuplicate
                          color={
                            value && value[rowId] && value[rowId][0] && value[rowId][0]['from'] && value[rowId][0]['to']
                              ? 'primary'
                              : 'disabled'
                          }
                        />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormGroup>
    </FormControl>
  )
}

WorkScheduleMultiTimeInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
  optionValue: PropTypes.string,
  row: PropTypes.bool,
  resource: PropTypes.string,
  translateChoice: PropTypes.bool,

  defaultValue: PropTypes.object,
}

WorkScheduleMultiTimeInput.defaultProps = {
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true,
  fullWidth: true,
  values: [],
  row: true,
}

export default WorkScheduleMultiTimeInput
