import * as React from 'react'
import { RecordContextProvider, useDataProvider, useRefresh, required, NumberInput, TextInput } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { PromoKind } from 'src/types/enum/PromoKind'
import PromoServiceDeliveryInput from 'src/components/inputs/PromoServiceDeliveryInput'

interface Props {
  isShown?: boolean
  id?: number
  onClose?: () => void
  isTakeaway: boolean
}

export const PromoCodeGenerateModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  //

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.create(props.isTakeaway ? 'promo-code-takeaway/generate' : 'promo-code/generate', {
      data: { ...data },
    })
    //
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'promo-code'}
        title={'Генерация промкодов доставки'}
        onClose={props.onClose}
        save={handleSubmit}
      >
        <TextInput
          source="codeTemplate"
          label={'Шаблон'}
          helperText={'Например TEST{code}'}
          fullWidth
          validate={[required()]}
        />
        <NumberInput source="count" label={'Кол-во промокодов'} helperText={''} fullWidth validate={[required()]} />
        <PromoServiceDeliveryInput
          resettable={true}
          source="promoId"
          label={'Акция'}
          filter={{ kind: PromoKind.Personal }}
          isTakeaway={props.isTakeaway}
          fullWidth
          validate={[required()]}
        />
        <NumberInput source="perCustomerUsageLimit" label={'Лимит для одного клиента'} helperText={''} fullWidth />
        <NumberInput source="usageLimit" label={'Общий лимит на промокод'} helperText={''} fullWidth />
      </ModalForm>
    </RecordContextProvider>
  )
}
