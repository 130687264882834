import * as React from 'react'
import { FC } from 'react'
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  ListProps,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin'
import EmptyList from 'src/components/list/EmptyList'
import { CancelReasonEntityTypeList, CancelReasonUserTypeList } from 'src/types/enum/CancelReason'
import CountryInput from '../../components/inputs/CountryInput'

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <SelectInput
        source={'entity'}
        label={'Назначение причины'}
        choices={CancelReasonEntityTypeList}
        alwaysOn
        resettable
      />
      <SelectInput source={'userType'} label={'Предназначен'} choices={CancelReasonUserTypeList} alwaysOn resettable />
      <CountryInput source={'countryId'} label={'Страна'} resettable />
    </Filter>
  )
}

const CancelReasonList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Причины отмены"
      hasCreate
      exporter={false}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Список причин отмены пуст'} hasCreate buttonText={'Добавить причину отмены'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'labelPartner'} label={'Текст для партнеров'} />
        <TextField source={'labelClient'} label={'Текст для клиентов'} />
        <SelectField source={'entity'} label={'Назначение причины'} choices={CancelReasonEntityTypeList} />
        <SelectField source={'userType'} label={'Предназначен'} choices={CancelReasonUserTypeList} />
        <BooleanField source={'active'} label={'Активна'} />
        <DateField source="createdAt" label={'Создан'} showTime />
      </Datagrid>
    </List>
  )
}

export default CancelReasonList
