import * as process from 'process'
import * as React from 'react'
import * as _ from 'lodash'
import { AutocompleteInput, ReferenceInput } from 'react-admin'
import { getTranslation } from 'src/utils/translation'
import { Project } from '../../../types/enum/Project'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  validate?: any
  fullWidth?: boolean
  onChange?: (val: any) => void
  emptyText?: string
  helperText?: string
  showOnlyInDandbProject?: boolean
}

const CountryInput = (props: Props) => {
  if (
    (_.isBoolean(props.showOnlyInDandbProject) ? props.showOnlyInDandbProject : true) &&
    process.env.REACT_APP_NODE_PROJECT !== Project.Dandb
  ) {
    return null
  }
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source={props.source}
      reference="country"
      resettable={props.resettable}
      filterToQuery={(searchText: string) => ({ ...(searchText ? { 'translations.||$eq': searchText } : {}) })}
      filter={{ 'translations.languageCode': 'ru' }}
      sort={{ field: 'translations.name', order: 'ASC' }}
      perPage={100}
    >
      <AutocompleteInput
        optionText={(i) => `${getTranslation(i)?.name || ''}`}
        label={props.label ?? 'Страна'}
        emptyText={props.emptyText}
        validate={props.validate}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </ReferenceInput>
  )
}

export default CountryInput
