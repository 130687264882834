import ListIcon from '@mui/icons-material/List'
import PromoList from 'src/resources/promo-service-units/PromoList'
import PromoEdit from 'src/resources/promo-service-units/PromoEdit'
import PromoCreate from 'src/resources/promo-service-units/PromoCreate'

export default {
  create: PromoCreate,
  edit: PromoEdit,
  list: PromoList,
  icon: ListIcon,
}
