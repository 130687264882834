export const PromoReserveFieldsMap = {
  status: 'Статус',
  prevStatus: 'Предыдущий статус',
  numOfPersons: 'Кол-во персон',
  cancelReason: 'Причина отмены',
  rejectionReason: 'Причина отмены',
  commission: 'Комиссия',
  total: 'Сумма чека',
  startAt: 'Начало брони',
  endAt: 'Конец брони',
  usedAt: 'Время посещения(сканирования)',
  totalFilledAt: 'Время заполнения суммы чека',
  isConfirmExpired: 'Время подтверждения истекло',
}
