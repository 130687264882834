export enum ApiIntegrationSyncType {
  Menu = 'menu',
  StopList = 'stopList',
  OrderSend = 'orderSend',
  OrderStatus = 'orderStatus',
  UnitArea = 'unitArea',
}
export const ApiIntegrationSyncTypeList = [
  { id: ApiIntegrationSyncType.Menu, name: 'Меню' },
  { id: ApiIntegrationSyncType.StopList, name: 'Стоп-лист' },
  { id: ApiIntegrationSyncType.OrderSend, name: 'Отправка заказа' },
  { id: ApiIntegrationSyncType.OrderStatus, name: 'Статус заказа' },
  { id: ApiIntegrationSyncType.UnitArea, name: 'Зоны доставки' },
]
