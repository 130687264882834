import * as React from 'react'
import {
  RecordContextProvider,
  useDataProvider,
  useRefresh,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  required,
  useNotify,
  NumberInput,
  TextInput,
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import { PromoPayerList } from 'src/types/enum/PromoPayer'
import { CancelReason, useCommonStore } from '../../../store/common.store'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import * as _ from 'lodash'

interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}

let cancelReasonsLoading = false

export const BanquetRefundCreateModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const notify = useNotify()
  //
  const { cancelReasons, setCancelReasons } = useCommonStore()
  const [selectedCancelReasonId, setSelectedCancelReasonId] = useState<string | null>(null)

  useEffect(() => {
    if (!cancelReasonsLoading && !cancelReasons) {
      cancelReasonsLoading = true
      //
      getCancelReasons()
    }
  }, [cancelReasonsLoading])

  const getCancelReasons = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL || ''}/admin/api/promo-reserve/cancel-reason/list`)

      if (res.status === 200) {
        setCancelReasons(res.data)
      }
    } catch (err) {
      cancelReasonsLoading = false
      //
      throw err
    }
  }

  const handleSubmit = async (data: FieldValues) => {
    try {
      await dataProvider.create(`banquet-reserve/${record.id}/cancel-and-refund`, { data })
      refresh()

      if (props.onClose) {
        props.onClose()
      }
    } catch (e: any) {
      notify(e.message.toString(), { type: 'error' })
    }
    return true
  }

  const SelfInput = useCallback(() => {
    let cancelReason: CancelReason | null = null

    if (_.isArray(cancelReasons) && !_.isNil(selectedCancelReasonId)) {
      cancelReason = cancelReasons.find((reason) => reason.id === selectedCancelReasonId) || null
    }
    if (!cancelReason || !cancelReason.selfInput) {
      return null
    }
    return <TextInput name={'selfInput'} source={'selfInput'} label={'Своя причина'} validate={required()} />
  }, [cancelReasons, selectedCancelReasonId])

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        fullWidth={true}
        resource={'banquet-reserve'}
        title={`Возрат по брони банкета № ${record.id}`}
        onClose={props.onClose}
        saveButtonLabel={'Подтвердить'}
        save={handleSubmit}
      >
        <SelectInput
          name={'cancelReasonId'}
          source={'cancelReasonId'}
          label={'Причина отмены'}
          choices={cancelReasons || []}
          disabled={!cancelReasons}
          onChange={(event) => setSelectedCancelReasonId(event.target.value)}
          validate={required()}
        />
        <SelfInput />
        <BooleanInput source="partialRefund" label={'Частичный возврат'} />
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) =>
            formData.partialRefund && <NumberInput source="refundSum" label={'Сумма возрата'} validate={[required()]} />
          }
        </FormDataConsumer>
        <SelectInput source="payer" label={'За чей счет возврат'} choices={PromoPayerList} validate={[required()]} />
      </ModalForm>
    </RecordContextProvider>
  )
}
