import * as React from 'react'
import { RecordContextProvider, TextInput, useGetIdentity, useNotify, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Formatter from '../../../utils/formatters'
import { TrashCan } from 'mdi-material-ui'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import * as _ from 'lodash'
import axios, { AxiosError } from 'axios'

interface Props {
  refresh?: boolean
  isShown?: boolean
  order: any
  onClose?: () => void
}

export const EditOrderModal = (props: Props) => {
  const { identity } = useGetIdentity()
  const refresh = useRefresh()
  const notify = useNotify()
  //
  const [orderLineIdsToBeDeleted, setOrderLineIdsToBeDeleted] = useState<string[]>([])
  //
  const handleSubmit = async (data: FieldValues): Promise<boolean> => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL || ''}/admin/api/order/${props.order.id}/edit`,
        {
          address: {
            ...data,
            address: _.filter([data.street, data.house]).join(', '),
          },
          orderLineIdsToBeDeleted,
        },
        {
          headers: {
            authorization: `Bearer ${identity?.token}`,
          },
        },
      )

      if (res.status === 200 || res.status === 201) {
        refresh()

        if (props.onClose) {
          props.onClose()
        }
        return true
      }
      throw Error()
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data?.message) {
        notify(err.response?.data?.message, { type: 'error' })
      } else {
        notify('При изменении заказа произошла ошибка', { type: 'error' })
      }
      return false
    }
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        fullWidth={true}
        maxWidth={'md'}
        resource={'order'}
        defaultValues={{
          city: props.order.address?.city,
          street: props.order.address?.street,
          house: props.order.address?.house,
          entrance: props.order.address?.entrance,
          floor: props.order.address?.floor,
          apartment: props.order.address?.apartment,
          intercomCode: props.order.address?.intercomCode,
        }}
        title={'Изменить адрес'}
        onClose={props.onClose}
        saveButtonLabel={'Изменить'}
        save={handleSubmit}
      >
        <Typography sx={{ ml: 0.7, mb: 2 }}>Адрес</Typography>
        <TextInput source="city" label={'Город'} fullWidth />
        <TextInput source="street" label={'Улица'} fullWidth />
        <TextInput source="house" label={'Дом'} fullWidth />
        <TextInput source="entrance" label={'Подъезд'} fullWidth />
        <TextInput source="floor" label={'Этаж'} fullWidth />
        <TextInput source="apartment" label={'Кв/офис'} fullWidth />
        <TextInput source="intercomCode" label={'Код домофона'} fullWidth />
        {/*<Typography sx={{ ml: 0.7, my: 2 }}>Состав заказа</Typography>*/}
        {/*<OrderLines*/}
        {/*  orderLines={props.order.lines?.filter((orderLine: any) => !orderLineIdsToBeDeleted.includes(orderLine.id))}*/}
        {/*  onDeleteOrderLine={(orderLineId: string) =>*/}
        {/*    setOrderLineIdsToBeDeleted([...orderLineIdsToBeDeleted, orderLineId])*/}
        {/*  }*/}
        {/*/>*/}
      </ModalForm>
    </RecordContextProvider>
  )
}

const OrderLines = ({
  orderLines,
  onDeleteOrderLine,
}: {
  orderLines?: any[]
  onDeleteOrderLine: (orderLineId: string) => void
}) => {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableBody>
          {orderLines?.map((item: any) => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  {item.quantity} x {item.product?.name}
                </TableCell>
                <TableCell align="right"> {Formatter.formatPrice(item.price)}</TableCell>
                <TableCell align="right">{Formatter.formatPrice(item.total)}</TableCell>
                <TableCell align="right">
                  <IconButton type={'button'} onClick={() => onDeleteOrderLine(item.id)}>
                    <TrashCan sx={{ width: 20, height: 20 }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
          {_.isEmpty(orderLines) && <Typography textAlign={'center'}>Пусто</Typography>}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
