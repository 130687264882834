import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, Datagrid, TextField, DateField, SelectField, SelectArrayInput } from 'react-admin'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import PercentField from 'src/components/fields/PercentField'
import RegionArrayInput from 'src/components/inputs/RegionArrayInput'
import { BanquetReserveStatusList } from 'src/types/enum/BanquetStatus'
import PriceField from 'src/components/fields/PriceField'
import UserField from 'src/components/fields/UserField'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import { ServiceType } from 'src/types/enum/ServiceType'
import UserInput from 'src/components/inputs/UserInput'
import CountryArrayInput from '../../components/inputs/CountryArrayInput'

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <UserInput source="userId" label={'Клиент'} fullWidth alwaysOn resettable />
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn />
      <RestaurantInput
        serviceType={ServiceType.Restaurants}
        alwaysOn
        resettable
        source="restaurantId"
        label={'Ресторан'}
      />
      <SelectArrayInput source="status:$in" label={'Статус'} alwaysOn choices={BanquetReserveStatusList} />
      <CountryArrayInput source={'restaurant.countryId:$in'} label={'Страны'} />
      <RegionArrayInput source={'restaurant.regionId:$in'} label={'Регионы'} />
    </Filter>
  )
}

const BanquetReserveList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Брони банкетов"
      exporter={false}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick={'show'}>
        <TextField source={'id'} label={'ID'} type={'show'} />
        <UserField
          source={'userId'}
          sourceId={'userId'}
          sourceName={'user.name'}
          sourcePhone={'user.phone'}
          label={'Клиент'}
        />
        <PartnerLinkField mainSource={'partner'} label={'Партнер'} />
        <RestaurantLinkField mainSource={'restaurant'} label={'Ресторан'} />
        <TextField source={'restaurant.region.name'} label={'Регион'} />
        <SelectField source={'status'} label={'Статус'} choices={BanquetReserveStatusList} />
        <PercentField source={'discountBar'} label={'Скидка на бар'} />
        <PercentField source={'discountKitchen'} label={'Скидка на кухню'} />
        <PriceField source={'depositAmount'} label={'Депозит'} />
        <DateField source="createdAt" label={'Создан'} showTime />
        <DateField source="totalFilledAt" label={'Завершен'} showTime />
      </Datagrid>
    </List>
  )
}

export default BanquetReserveList
