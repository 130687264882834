import * as React from 'react'
import { ChangeEventHandler, useState } from 'react'
import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useResourceContext,
} from 'react-admin'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { useFormContext } from 'react-hook-form'
import { getTranslation } from 'src/utils/translation'
import { RichTextInput } from 'src/components/inputs/RichTextInput'
import { ArticleType } from 'src/types/enum/ArticleType'
import LanguageInput from 'src/components/inputs/LanguageInput'
import CountryInput from '../../components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'

const Form = (props: any) => {
  const form = useFormContext()
  const formValues = form.watch()
  //
  const [countryId, setCountryId] = useState<string | null>(null)

  const handleCountryChange = (val: string | null) => {
    form.setValue('articleCategoryId', null)
    setCountryId(val)
  }

  const handleArticleTypeChange: ChangeEventHandler<HTMLInputElement> = () => {
    form.setValue('articleCategoryId', null)
  }

  return (
    <>
      <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'} />
      <CountryInput
        source="countryId"
        label={'Страна'}
        onChange={handleCountryChange}
        validate={[required()]}
        fullWidth
      />
      <ReferenceInput
        source="articleCategoryId"
        reference="article-category"
        variant={'outlined'}
        filter={{
          'translations.languageCode': 'ru',
          categoryType: ArticleType.Article,
          ...getCountryIdFilterBasedOnProject(countryId),
        }}
        sort={{ field: 'translations.name', order: 'ASC' }}
        onChange={handleArticleTypeChange}
        perPage={100}
      >
        <SelectInput
          source={'id'}
          label="Категория"
          optionText={(record) => `${getTranslation(record)?.name}`}
          fullWidth
          validate={required()}
        />
      </ReferenceInput>

      <DateTimeInput label="Время публикации" source="publishedAt" variant={'outlined'} fullWidth={true} />

      <MediaInput source={'image'} label={'Картинка'} />
      <MediaInput source={'imageLarge'} label={'Большая картинка'} />
      <NumberInput
        source={'minutesToRead'}
        multiline={true}
        label={'Минут на чтение'}
        variant={'outlined'}
        fullWidth={true}
      />
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <LanguageInput source="languageCode" label={'Язык'} />
          <TextInput source={'name'} label={'Название'} validate={required()} fullWidth={true} variant={'outlined'} />
          <RichTextInput
            source="body"
            label={'Содержимое'}
            fullWidth={true}
            variant={'outlined'}
            validate={required()}
          />
          <TextInput source={'seoTitle'} label={'SEO title'} fullWidth={true} variant={'outlined'} />
          <TextInput source={'seoDescription'} label={'SEO Description'} fullWidth={true} variant={'outlined'} />
          <TextInput source={'seoKeywords'} label={'SEO Keywords'} fullWidth={true} variant={'outlined'} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

export const ArticleForm = (props: any) => {
  const resource = useResourceContext()
  const getDefaultType = () => {
    switch (resource) {
      case 'article':
        return ArticleType.Article
    }
  }
  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      defaultValues={{ articleType: getDefaultType(), translations: [{ languageCode: 'ru' }] }}
    >
      <Form />
    </SimpleForm>
  )
}
