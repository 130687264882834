import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextInput, TextField, SelectInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate, formatListValue } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { PriceRatingList } from 'src/types/enum/PriceRating'
import { PartnerCommissionTypeList } from 'src/types/enum/PartnerCommissionType'
import VisibilityField from 'src/components/fields/VisibilityField'
import AssetField from 'src/components/fields/AssetField'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import CountryInput from '../../components/inputs/CountryInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      name: 'Название',
      partnerName: 'Партнер',
      commissionType: 'Тип комиссии',
      priceRating: 'Сегмент',
      createdAt: 'Создан',
    },
    data: posts.map((item: any) => ({
      ...item,
      partnerName: item.partner?.name,
      commissionType: formatListValue(item.commissionType, PartnerCommissionTypeList),
      priceRating: formatListValue(item.priceRating, PriceRatingList),
      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Brand',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="name:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn={true} resettable={true} />
      <CountryInput source="partner.countryId" label={'Страна'} resettable />
      <IfCanAccess source={'partnerId'} label={'Партнер'} aggregator action={'show'}>
        <PartnerInput source={'partnerId'} label={'Партнер'} />
      </IfCanAccess>
      <SelectInput source="priceRating" label={'Сегмент'} choices={PriceRatingList} fullWidth />
    </Filter>
  )
}

const BrandList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Бренды"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <AssetField source={'logo'} label={''} />
        <TextField source={'name'} label={'Бренд'} />
        <IfCanAccess aggregator label={'Партнер'} action={'show'}>
          <TextField source="partner.name" label={'Партнер'} />
        </IfCanAccess>
        <VisibilityField source={'published'} label={'Видимость'} />
      </Datagrid>
    </List>
  )
}

export default BrandList
