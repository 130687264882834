import * as React from 'react'
import { FC } from 'react'
import { Datagrid, DateField, Filter, List, ListProps, SelectArrayInput, SelectField, TextField } from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import RegionArrayInput from 'src/components/inputs/RegionArrayInput'
import PriceField from 'src/components/fields/PriceField'
import UserField from 'src/components/fields/UserField'
import { BanquetStatusList } from 'src/types/enum/BanquetStatus'
import UserInput from 'src/components/inputs/UserInput'
import CountryArrayInput from '../../components/inputs/CountryArrayInput'

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <UserInput source="userId" label={'Клиент'} alwaysOn resettable />
      <SelectArrayInput source="status:$in" label={'Статус'} alwaysOn choices={BanquetStatusList} />
      <CountryArrayInput source={'region.countryId:$in'} label={'Страны'} alwaysOn />
      <RegionArrayInput source={'regionId:$in'} label={'Регионы'} alwaysOn />
    </Filter>
  )
}

const BanquetList: FC<ListProps> = (props) => {
  return (
    <List {...props} filters={<_Filter />} title="Банкет" exporter={false} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={'show'}>
        <IdShowButtonField source={'id'} label={'ID'} type={'show'} />
        <TextField source={'region.name'} label={'Регион'} />
        <UserField
          source={'userId'}
          sourceId={'userId'}
          sourceName={'user.name'}
          sourcePhone={'user.phone'}
          label={'Клиент'}
        />
        <SelectField source={'status'} label={'Статус'} choices={BanquetStatusList} />
        <TextField source={'numOfPersons'} label={'Количество персон'} />
        <PriceField source={'amountPerPerson'} label={'Депозит за 1 персону'} />
        <PriceField source={'depositAmount'} label={'Сумма депозита'} />
        <DateField source="createdAt" label={'Создан'} showTime />
      </Datagrid>
    </List>
  )
}

export default BanquetList
