import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, SelectField, TextInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { SubscriptionTypeList } from 'src/types/enum/SubscriptionType'
import PriceField from 'src/components/fields/PriceField'
import { Datagrid } from 'src/components/list/Datagrid'
import CountryInput from '../../components/inputs/CountryInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'SubscriptionDescription',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="key:$contL" label={'Поиск'} variant={'outlined'} alwaysOn resettable />
      <CountryInput source="countryId" label={'Страна'} alwaysOn resettable />
    </Filter>
  )
}

const SubscriptionDescriptionList: FC<ListProps> = (props) => {
  return (
    <List
      filters={<_Filter />}
      title="Тарифы подписок"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'key'} label="Ключ" />
        <TextField source={'description'} label="Описание" />
        <SelectField source={'type'} label="Тип" choices={SubscriptionTypeList} />
        <PriceField source={'price'} label="Цена" />
        <TextField source={'days'} label="Кол-во дней подписки" />
        <PriceField source={'firstTimePrice'} label="Цена первой покупки" />
      </Datagrid>
    </List>
  )
}

export default SubscriptionDescriptionList
