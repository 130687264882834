import { ServiceType } from 'src/types/enum/ServiceType'

interface IDictionary {
  single: string
  multi: string
  create: string
}
type DictionaryType = { [key in ServiceType]: IDictionary }
export class ServiceTypeDictionary {
  static getServiceEntityDictionary(): DictionaryType {
    return {
      [ServiceType.Coffee]: { single: 'Кофейня', multi: 'Кофейни', create: 'Добавить кофейню' },
      [ServiceType.Restaurants]: { single: 'Ресторан', multi: 'Рестораны', create: 'Добавить ресторан' },
      [ServiceType.Takeaway]: { single: 'Заведение', multi: 'Заведения', create: 'Добавить заведение' },
      [ServiceType.Delivery]: { single: 'Заведение', multi: 'Заведения', create: 'Добавить заведение' },
    }
  }
  static getPromoRestaurantDictionary(): DictionaryType {
    return {
      [ServiceType.Coffee]: { single: 'Акция кофейни', multi: 'Акции кофеен', create: 'Добавить акцию кофейню' },
      [ServiceType.Restaurants]: {
        single: 'Акция ресторана',
        multi: 'Акции ресторанов',
        create: 'Добавить акциюю ресторана',
      },
      [ServiceType.Takeaway]: { single: 'Акция самовывоза', multi: 'Акции самовывоза', create: 'Добавить акцию' },
      [ServiceType.Delivery]: { single: 'Акция доставки', multi: 'Акции доставок', create: 'Добавить акцию' },
    }
  }
  static getPromoDictionary(): DictionaryType {
    return {
      [ServiceType.Coffee]: {
        single: 'Акция для кофейни',
        multi: 'Акции для кофеен',
        create: 'Добавить акцию для кофейню',
      },
      [ServiceType.Restaurants]: {
        single: 'Акция для ресторана',
        multi: 'Акции для ресторанов',
        create: 'Добавить акцию для ресторана',
      },
      [ServiceType.Takeaway]: {
        single: 'Акция для самовывоза',
        multi: 'Акции для самовывоза',
        create: 'Добавить акцию',
      },
      [ServiceType.Delivery]: { single: 'Акция для доставки', multi: 'Акции для доставок', create: 'Добавить акцию' },
    }
  }
  static getServiceEntity(resource: string | ServiceType): IDictionary | undefined {
    switch (resource) {
      case ServiceType.Restaurants:
      case 'promo-restaurant':
      case 'restaurant':
        return this.getServiceEntityDictionary()[ServiceType.Restaurants]
      case ServiceType.Coffee:
      case 'restaurant-coffee':
        return this.getServiceEntityDictionary()[ServiceType.Coffee]
    }
  }
  static getPromoRestaurant(resource: string): IDictionary | undefined {
    switch (resource) {
      case 'promo-restaurant':
        return this.getPromoRestaurantDictionary()[ServiceType.Restaurants]
      case 'promo-restaurant-coffee':
        return this.getPromoRestaurantDictionary()[ServiceType.Coffee]
    }
  }
  static getPromo(resource: string): IDictionary | undefined {
    switch (resource) {
      case 'promo-service-restaurants':
        return this.getPromoDictionary()[ServiceType.Restaurants]
      case 'promo-service-coffee':
        return this.getPromoDictionary()[ServiceType.Coffee]
    }
  }

  static convertResource(resource: string): ServiceType {
    switch (resource) {
      case 'restaurant-coffee':
      case 'promo-restaurant-coffee':
      case 'promo-service-coffee':
        return ServiceType.Coffee
      case 'restaurant':
      case 'promo-restaurant':
      case 'promo-service-restaurants':
      default:
        return ServiceType.Restaurants
    }
  }
}
