import { ServiceType } from './ServiceType'

export enum OrderType {
  Takeaway = ServiceType.Takeaway,
  Delivery = ServiceType.Delivery,
}

export const OrderTypeList = [
  { id: OrderType.Takeaway, name: 'Самовывоз' },
  { id: OrderType.Delivery, name: 'Доставка' },
]
