import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin'
import * as React from 'react'
import * as _ from 'lodash'
import { Project } from '../../../types/enum/Project'
import * as process from 'process'
import { getTranslation } from '../../../utils/translation'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  fullWidth?: boolean
  validate?: any
  showOnlyInDandbProject?: boolean
  alwaysOn?: boolean
}

const CountryArrayInput = (props: Props) => {
  if (
    (_.isBoolean(props.showOnlyInDandbProject) ? props.showOnlyInDandbProject : true) &&
    process.env.REACT_APP_NODE_PROJECT !== Project.Dandb
  ) {
    return null
  }
  return (
    <ReferenceArrayInput
      alwaysOn={props.alwaysOn}
      allowEmpty={false}
      source={props.source}
      reference="country"
      variant={'outlined'}
      fullWidth={true}
      enableGetChoices={({ q }) => q && q.length >= 2}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        label={props.label}
        validate={props.validate}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        source="id"
        optionText={(i) => `${getTranslation(i)?.name || ''}`}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
    </ReferenceArrayInput>
  )
}

export default CountryArrayInput
