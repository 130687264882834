import * as React from 'react'
import {
  Show,
  TextField,
  FunctionField,
  SimpleShowLayout,
  EditButton,
  DateField,
  Pagination,
  ReferenceManyField,
  TopToolbar,
  SelectField,
  DeleteButton,
} from 'react-admin'
import { OrderStatusesFinished, AllOrdersStatusesList } from 'src/types/enum/OrderStatus'
import { PaymentStatus, PaymentStatusList } from 'src/types/enum/PaymentStatus'
import { useRecordContext } from 'ra-core'
import Box from '@mui/material/Box'
import RelatedList from 'src/components/list/RelatedList'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { ModalButton } from 'src/components/Modal/ModalButton'
import PauseIcon from '@mui/icons-material/Pause'
import { OrderRefundCreateModal } from 'src/resources/order/OrderRefundCreateModal'
import OrderLinkField from 'src/components/fields/OrderLinkField'
import UnitLinkField from 'src/components/fields/UnitLinkField'
import { ReviewStatusList } from 'src/types/enum/ReviewStatus'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import { PaymentMethodList } from 'src/types/enum/PaymentMethod'
import PriceField from 'src/components/fields/PriceField'
import PhoneField from 'src/components/fields/PhoneField'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { ReviewAnswerModal } from 'src/resources/review/ReviewAnswerModal'
import { GenderList } from 'src/types/enum/Gender'
import Formatter from 'src/utils/formatters'
import { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import { ComplaintStatusList } from 'src/types/enum/ComplaintStatus'
import { AddNoteToUserModal } from '../AddNoteToUserModal'

const Header = (props: { children: ReactElement | string }) => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle1">{props.children}</Typography>
      <Divider color={'#dbdbdc'} />
    </Box>
  )
}
const Actions = () => {
  const record = useRecordContext()
  //
  return (
    <TopToolbar>
      {OrderStatusesFinished.includes(record.status) &&
        ![
          PaymentStatus.RejectRegistered,
          PaymentStatus.RefundRegistered,
          PaymentStatus.Refund,
          PaymentStatus.Refunded,
        ].includes(record.paymentStatus) && (
          <ModalButton label={'Возврат'} icon={<PauseIcon />} modal={<OrderRefundCreateModal />} />
        )}

      <IfCanAccess aggregator resource={'use'} action={'edit'}>
        <Box ml={3}>
          <EditButton />
        </Box>
        <Box ml={3}>
          <ModalButton
            label={'Добавить пометку'}
            icon={<QuestionAnswerIcon />}
            key={'import'}
            modal={<AddNoteToUserModal />}
          />
        </Box>
      </IfCanAccess>
    </TopToolbar>
  )
}

const UserAddresses = () => {
  const record = useRecordContext()
  //
  return (
    <ReferenceManyField
      reference="user-address"
      target="userId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={3}
    >
      <RelatedList
        record={record}
        resource={'area'}
        hasCreate={false}
        emptyTitle={'Пока нет адресов'}
        bulkActionButtons={false}
      >
        <FunctionField source={'address'} render={(i: any) => Formatter.formatAddress(i)} label={'Адрес'} />
        <TextField source={'comment'} label={'Комментарий'} />

        <DateField source="createdAt" label={'Время'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const ReviewAnswerField = () => {
  const record = useRecordContext()
  //
  return record.answer ? (
    <span>Есть ответ</span>
  ) : (
    <ModalButton label={'Ответить'} icon={<QuestionAnswerIcon />} key={'import'} modal={<ReviewAnswerModal />} />
  )
}

const ComplaintAnswerField = () => {
  const record = useRecordContext()
  //
  return record.answer ? (
    <span>Есть ответ</span>
  ) : (
    <ModalButton label={'Ответить'} icon={<QuestionAnswerIcon />} key={'import'} modal={<ReviewAnswerModal />} />
  )
}

const UserComplaints = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="complaint"
      target="userId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={5}
    >
      <RelatedList
        record={record}
        resource={'complaint'}
        hasCreate={false}
        emptyTitle={'Пока нет жалоб'}
        bulkActionButtons={false}
        rowClick={'show'}
      >
        <DateField source="createdAt" label={'Время'} showTime={true} />
        <OrderLinkField label={'Заказ'} />
        <TextField source={'unit.brand.name'} label={'Бренд'} />
        <UnitLinkField label={'Заведение'} />
        <SelectField source={'status'} label={'Статус'} choices={ComplaintStatusList} />
        <TextField source={'text'} label={'Отзыв'} />
        <ComplaintAnswerField />
      </RelatedList>
    </ReferenceManyField>
  )
}

const UserReviews = () => {
  const record = useRecordContext()
  return (
    <ReferenceManyField
      reference="review"
      target="userId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={5}
    >
      <RelatedList
        record={record}
        resource={'area'}
        hasCreate={false}
        emptyTitle={'Пока нет отзывов'}
        bulkActionButtons={false}
      >
        <DateField source="createdAt" label={'Время'} showTime={true} />
        <OrderLinkField label={'Заказ'} />
        <UnitLinkField label={'Заведение'} />
        <SelectField label={'Статус'} choices={ReviewStatusList} />
        <TextField source={'mark'} label={'Оценка'} />
        <TextField source={'text'} label={'Отзыв'} />
        <ReviewAnswerField />
      </RelatedList>
    </ReferenceManyField>
  )
}

const UserOrders = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="order"
      target="userId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={5}
    >
      <RelatedList
        record={record}
        resource={'area'}
        hasCreate={false}
        emptyTitle={'Пока нет заказов'}
        bulkActionButtons={false}
      >
        <IdShowButtonField type={'show'} source={'id'} label={'ID'} />
        <TextField source={'number'} label={'Номер'} />
        <SelectField source={'status'} label={'Статус'} choices={AllOrdersStatusesList} />
        <SelectField source={'paymentStatus'} label={'Статус оплаты'} choices={PaymentStatusList} />
        <SelectField source={'paymentMethod'} label={'Тип оплаты'} choices={PaymentMethodList} />
        <PriceField source={'total'} label={'Сумма'} />
        <PhoneField source={'phone'} label={'Телефон'} />
        <TextField source={'name'} label={'Имя'} />
        <DateField source="createdAt" label={'Создан'} showTime={true} />
        <TextField source={'partner.name'} label={'Партнер'} />
        <TextField source={'brand.name'} label={'Бренд'} />
        <TextField source={'unit.name'} label={'Заведение'} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const NotesToUser = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="notes-to-user"
      target="userId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'notes-to-user'}
        hasCreate={false}
        emptyTitle={'Пока нет пометок'}
        bulkActionButtons={false}
      >
        <TextField source={'admin.name'} label={'Менеджер'} />
        <TextField source={'note'} label={'Пометка'} />
        <DateField source="createdAt" label={'Время'} showTime={true} />
        <DeleteButton redirect={false} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const Index = () => {
  return (
    <Show emptyWhileLoading actions={<Actions />}>
      <SimpleShowLayout>
        <TextField source={'name'} label={'Имя'} />
        <PhoneCallActionField source={'phone'} label={'Телефон'} />
        <TextField source={'email'} label={'Email'} />
        <TextField source={'birthdate'} label={'Дата рождения'} />
        <SelectField source={'gender'} label={'Пол'} choices={GenderList} />
        <FunctionField
          source={'currentAddress'}
          render={(i: any) => Formatter.formatAddress(i.currentAddress)}
          label={'Текущий адрес'}
        />
        <Header>Заказы</Header>
        <UserOrders />
        <Header>Адреса</Header>
        <UserAddresses />
        <Header>Отзывы</Header>
        <UserReviews />
        <Header>Жалобы</Header>
        <UserComplaints />
        <Header>О клиенте</Header>
        <NotesToUser />
      </SimpleShowLayout>
    </Show>
  )
}
export default Index
