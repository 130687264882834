import * as React from 'react'
import {
  TabbedForm,
  TextInput,
  FormTab,
  SelectInput,
  required,
  RadioButtonGroupInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  DateTimeInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import { PromoKind, PromoKindList } from 'src/types/enum/PromoKind'
import { PromoType, PromoTypeList } from 'src/types/enum/PromoType'
import { DiscountType, DiscountTypeList } from 'src/types/enum/DiscountType'
import RegionInput from 'src/components/inputs/ReqionInput'
import { range } from 'src/utils/array'
import { PromoPayerList } from 'src/types/enum/PromoPayer'
import PromoFormViewTab from 'src/components/Promo/PromoFormViewTab'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'
import { ServiceType } from 'src/types/enum/ServiceType'
import { PromoGroupList } from 'src/types/enum/PromoGroup'
import { PromoTemplate } from 'src/types/enum/PromoTemplate'
import { PromoRecordChanges } from '../../components/PromoRecordChanges'
import { useMemo, useState } from 'react'
import CountryInput from '../../components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'

const InfoTab = (props: any) => {
  return (
    <FormTab label="Акция" path={'benefit'} {...props}>
      <BooleanInput source="published" label={'Активен'} fullWidth />
      <RadioButtonGroupInput source="kind" label={''} choices={PromoKindList} validate={[required()]} fullWidth />
      <SelectInput source="type" label={'Тип'} choices={PromoTypeList} validate={[required()]} fullWidth />
      <SelectInput source="group" label={'Группа'} choices={PromoGroupList} resettable={true} fullWidth />
      <NumberInput source={'sort'} label={'Сортировка'} />
      <SelectInput
        source={'payer'}
        label={'Плательщик'}
        disabled={props.isEdit}
        choices={PromoPayerList}
        validate={[required()]}
        fullWidth
        helperText={'Можно выбрать только при создании новой акции'}
      />
      <TextInput
        source="innerName"
        label={'Внутреннее название'}
        helperText={'Отображается только в лк партнера и агрегататора'}
        fullWidth
      />
      <TextInput
        source="promoCode"
        label={'Промокод скидки (только для передачи заказов по API)'}
        helperText={
          'Введеный промокод предназначен для передачи в данных заказа по API. Данная механика позволяет определить на стороне партнера, какая скидка была применена, и связять со своими скидками'
        }
        fullWidth
      />

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.type === PromoType.EachN && (
            <SelectInput
              source="nOrder"
              label={'Значение N заказа'}
              choices={range(2, 10, 1).map((i) => ({ id: i, name: `${i}` }))}
              validate={[required()]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          ![PromoType.OnePlusOne, PromoType.Gift].includes(formData.type as PromoType) && (
            <SelectInput
              source="discountType"
              label={'Тип Скидки'}
              choices={DiscountTypeList}
              validate={[required()]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          ![PromoType.OnePlusOne, PromoType.Gift].includes(formData.type as PromoType) && (
            <TextInput
              source="discountValue"
              label={formData.discountType === DiscountType.Fixed ? 'Фиксированная сумма' : 'Процент от заказа'}
              validate={[required()]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) => (
          <ArrayInput source="discountByOrder" label={'Значение скидки в зависимости от суммы заказа'} fullWidth>
            <SimpleFormIterator fullWidth>
              <NumberInput
                source="value"
                label={formData.discountType === DiscountType.Fixed ? 'Фиксированная сумма' : 'Процент от заказа'}
                fullWidth
                validate={required()}
              />
              <NumberInput source="order" label={'Сумма заказа'} fullWidth validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </FormDataConsumer>
    </FormTab>
  )
}

const ConditionsTab = (props: any) => {
  const [countryId, setCountryId] = useState<string | null>(null)
  //
  const isTakeaway = useMemo(() => window.location.href.includes('-takeaway'), [window.location.href])

  return (
    <FormTab label="Условия" path={'benefit'} {...props}>
      <CountryInput
        source={'countryId'}
        label={'Страна'}
        allowEmpty={false}
        fullWidth
        onChange={(val) => {
          setCountryId(val)
        }}
        validate={required()}
      />
      <RegionInput
        source={'regionId'}
        label={'Регион'}
        emptyText={'Все'}
        fullWidth
        filter={getCountryIdFilterBasedOnProject(countryId)}
      />
      <DateTimeInput label="Начало действия" source="startsAt" variant={'outlined'} fullWidth />
      <DateTimeInput label="Окончание действия" source="endsAt" variant={'outlined'} fullWidth />

      <CategoriesArrayInput
        source={'categoriesIds'}
        label={'Товарные разделы'}
        filter={{ serviceType: ServiceType.Delivery, ...getCountryIdFilterBasedOnProject(countryId) }}
      />
      <NumberInput source="settings.minOrderAmount" label={'Минимальная сумма заказа'} fullWidth />
      <NumberInput source="settings.minProductsCount" label={'Минимальное кол-во блюд'} fullWidth />
      <NumberInput source="settings.minProductsPercent" label={'Минимальный процент от всей нуменклатуры'} fullWidth />

      <BooleanInput
        source="settings.canExcludeCategories"
        label={'Партнер может исключать категории агрегатора'}
        fullWidth
      />
      <BooleanInput
        source="settings.canIncludeCategories"
        label={'Партнер может выбирать Товарные разделы агрегатора'}
        fullWidth
      />
      <BooleanInput
        source="settings.canExcludeProductCategories"
        label={'Партнер может исключать категории своего меню'}
        fullWidth
      />
      <BooleanInput
        source="settings.canIncludeProductCategories"
        label={'Партнер может выбирать категории своего меню'}
        fullWidth
      />
      <BooleanInput source="settings.canIncludeProducts" label={'Партнер может выбирать блюда'} fullWidth />
      <BooleanInput
        source="settings.dontCheckMinimumAmount"
        label={'Не проверять минимальную сумму заказа'}
        fullWidth
      />

      {!isTakeaway && (
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) => (
            <BooleanInput
              source="settings.includeDeliveryPriceIntoPromoFixedPriceGift"
              label={'Включить сумму доставки в сумму акции "Сумма в подарок"'}
              fullWidth
              value={
                formData.type &&
                formData.type === PromoType.MoneyGift &&
                formData.discountType &&
                formData.discountType === DiscountType.Fixed
                  ? undefined
                  : false
              }
              disabled={
                !formData.type ||
                !formData.discountType ||
                formData.type !== PromoType.MoneyGift ||
                formData.discountType !== DiscountType.Fixed
              }
            />
          )}
        </FormDataConsumer>
      )}
      <BooleanInput
        source="settings.canChangeOrderAmount"
        label={'Партнер может менять минимальную сумму заказа'}
        fullWidth
      />
      <BooleanInput source="settings.canSchedule" label={'Может использовать расписание'} fullWidth />
      <SelectInput
        source="settings.perCustomerUsageLimit"
        label={'Максимальное кол-во заказов клиента'}
        emptyText={'Не использовать'}
        resettable={true}
        choices={[{ id: 1, name: 'Первый заказ' }, ...range(2, 10, 1).map((i) => ({ id: i, name: `${i}` }))]}
        fullWidth
      />
    </FormTab>
  )
}

const PromoForm = (props: any) => {
  return (
    <>
      <TabbedForm
        {...props}
        redirect={'list'}
        defaultValues={{
          published: true,
          template: PromoTemplate.WithImageGradient,
          kind: PromoKind.Public,
        }}
      >
        <InfoTab isEdit={props.isEdit} />
        <PromoFormViewTab />
        <ConditionsTab />
      </TabbedForm>
      {props.isEdit && <PromoRecordChanges />}
    </>
  )
}

export default PromoForm
