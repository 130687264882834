import * as React from 'react'
import { FC, useMemo } from 'react'
import {
  Filter,
  List,
  FunctionField,
  ListProps,
  TextInput,
  TextField,
  SelectField,
  DateField,
  useRefresh,
  SelectArrayInput,
} from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import RegionArrayInput from 'src/components/inputs/RegionArrayInput'
import PartnerArrayInput from 'src/components/inputs/PartnerArrayInput'
import RestaurantArrayInput from 'src/components/inputs/RestaurantArrayInput'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import { PromoReserveStatusList } from 'src/types/enum/PromoReserveStatus'
import { Datagrid } from 'src/components/list/Datagrid'
import { colors } from 'src/style'
import { useRecursiveTimeout } from 'src/components/hooks/useRecursiveTimeout'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import { PromoReserveUtils } from 'src/utils/PromoReserveUtils'
import CoffeeShopArrayInput from 'src/components/inputs/CoffeeShopArrayInput'
import CountryArrayInput from '../../components/inputs/CountryArrayInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'PromoReserve',
  })
}

const _Filter = (props: any) => {
  const isCoffeeShopList = useMemo(() => {
    return window.location.href.includes('-coffee')
  }, [window.location.href])

  return (
    <Filter {...props}>
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn />
      <TextInput source="user.phone:$contL" label={'Поиск'} variant={'outlined'} alwaysOn resettable />
      <TextInput source="id" label={'ID брони'} variant={'outlined'} />
      <SelectArrayInput source="status:$in" label={'Статус'} variant={'outlined'} choices={PromoReserveStatusList} />
      <CountryArrayInput source={'restaurant.countryId:$in'} label={'Страны'} />
      <RegionArrayInput source={'restaurant.regionId:$in'} label={'Регионы'} />
      <PartnerArrayInput source={'restaurant.partnerId:$in'} label={'Партнеры'} />
      {isCoffeeShopList ? (
        <CoffeeShopArrayInput source={'restaurantId:$in'} label={'Заведения'} alwaysOn />
      ) : (
        <RestaurantArrayInput source={'restaurantId:$in'} label={'Заведения'} alwaysOn />
      )}
    </Filter>
  )
}

export const DataGridRowProps = {
  rowStyle: (record: any, index: number) => {
    return {
      ...(record.isConfirmExpired ? { backgroundColor: colors.rowBgRed, color: colors.rowColorRed } : {}),
    }
  },
}

const PromoReserveList: FC<ListProps> = (props) => {
  const refresh = useRefresh()
  useRecursiveTimeout(() => refresh(), 30 * 1000)

  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Брони"
      hasCreate={false}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет броней'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'show'} {...DataGridRowProps}>
        <TextField type={'show'} source={'id'} label={'ID'} />
        <SelectField source={'status'} label={'Статус'} choices={PromoReserveStatusList} />
        <PhoneCallActionField source={'user.phone'} label={'Телефон'} />
        <FunctionField
          source="startAt"
          label={'Время брони'}
          render={(i: any) =>
            PromoReserveUtils.formatBookingTime({
              startAt: i.startAt,
              endAt: i.endAt,
              timezone: i.timezone ?? i.restaurant?.timezone,
            })
          }
        />
        <TextField source={'numOfPersons'} label={'Персон'} />
        <PartnerLinkField source={'restaurant.partner.id'} mainSource={'restaurant.partner'} label={'Партнер'} />
        <RestaurantLinkField source={'restaurant.id'} label={'Ресторан'} />
        <DateField source="confirmUntil" label={'Подтвердить до'} showTime={true} />

        <DateField source="createdAt" label={'Создан'} showTime={true} />
        <DateField source="usedAt" label={'Использован'} showTime={true} />
        <DateField source="totalFilledAt" label={'Завршен'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default PromoReserveList
