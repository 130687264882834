import { AutocompleteInput, FilterPayload, ReferenceInput } from 'react-admin'
import * as React from 'react'
import * as _ from 'lodash'
import { PromoKindList } from 'src/types/enum/PromoKind'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  validate?: any
  fullWidth?: boolean
  filter?: FilterPayload
  helperText?: string
  onChange?: (val: any) => void
  isTakeaway?: boolean
}

const PromoUnitInput = (props: Props) => {
  const getPromoText = (promoUnit: any) => {
    const promoName = promoUnit?.promo?.name
    const promoKindText = PromoKindList.find((el) => el.id === promoUnit.promo?.kind)?.name || ''

    if (_.isEmpty(promoKindText)) {
      return promoName
    }
    return `${promoKindText}: ${promoName}`
  }

  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source="promoUnitId"
      reference={props.isTakeaway ? 'promo-unit-takeaway' : 'promo-unit'}
      variant={'outlined'}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filter={props.filter}
      perPage={100}
      enableGetChoices={({ q }) => q && q.length >= 2}
      disabled={props.disabled}
      {...(_.omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        validate={props.validate}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'unit.brand.name:$contL': q })}
        optionText={(i) => `${i?.partner?.name || ''} | ${i?.unit?.brand?.name} ${i?.unit?.name} | ${getPromoText(i)}`}
        optionValue="id"
        label="Акция заведения"
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </ReferenceInput>
  )
}

export default PromoUnitInput
