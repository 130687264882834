import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, SelectInput, SelectField, TextInput } from 'react-admin'

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import UserField from 'src/components/fields/UserField'
import OrderLinkField from 'src/components/fields/OrderLinkField'
import UnitLinkField from 'src/components/fields/UnitLinkField'
import { range } from 'src/utils/array'
import UserInput from 'src/components/inputs/UserInput'
import OrderInput from 'src/components/inputs/OrderInput'
import UnitInput from 'src/components/inputs/UnitInput'
import { ModalButton } from 'src/components/Modal/ModalButton'
import { ReviewAnswerModal } from 'src/resources/review/ReviewAnswerModal'
import { useRecordContext } from 'ra-core'
import { ReviewStatusList } from 'src/types/enum/ReviewStatus'
import BrandInput from 'src/components/inputs/BrandInput'
import { Datagrid } from 'src/components/list/Datagrid'
import CountryInput from '../../components/inputs/CountryInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Review',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput
        source="order.number:$eq&user.phone:$contL"
        label={'Поиск'}
        variant={'outlined'}
        alwaysOn={true}
        resettable={true}
      />

      <SelectInput source={'status'} alwaysOn={true} label="Статус" resettable={true} choices={ReviewStatusList} />
      <SelectInput
        source={'mark'}
        label="Оценка"
        resettable={true}
        choices={range(1, 5, 1).map((i) => ({ id: i, name: `${i}` }))}
      />
      <CountryInput source="unit.countryId" label={'Страна'} resettable />
      <UserInput source="userId" label={'Клиент'} />
      <OrderInput source="orderId" label={'Заказ'} />
      <UnitInput alwaysOn={true} resettable={true} source="unitId" label={'Заведение'} />
      <BrandInput resettable={true} source="brandId" label={'Бренд'} />
    </Filter>
  )
}

const AnswerField = (props: any) => {
  const record = useRecordContext()
  return record.answer ? (
    <span>Есть ответ</span>
  ) : (
    <ModalButton label={'Ответить'} icon={<QuestionAnswerIcon />} key={'import'} modal={<ReviewAnswerModal />} />
  )
}
const ReviewList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={<_Filter />}
      title="Отзывы"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <DateField source="createdAt" label={'Время'} showTime={true} />
        <OrderLinkField label={'Заказ'} />
        <UnitLinkField label={'Заведение'} />
        <UserField
          sourceId={'userId'}
          source={'userId'}
          sourceName={'user.name'}
          sourcePhone={'user.phone'}
          label={'Клиент'}
        />
        <SelectField source={'status'} label={'Статус'} choices={ReviewStatusList} />
        <TextField source={'mark'} label={'Оценка'} />
        <TextField source={'text'} label={'Отзыв'} />
        <AnswerField label={'Ответ'} source={'answer'} />
      </Datagrid>
    </List>
  )
}

export default ReviewList
