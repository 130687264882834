import * as _ from 'lodash'

export enum OrderStatus {
  Received = 'received',
  Waiting = 'waiting',
  OnHold = 'onHold',
  Sending = 'sending',
  Confirmed = 'confirmed',
  Preparing = 'preparing',
  Ready = 'ready',
  Sent = 'sent',
  Assigned = 'assigned',
  Delivering = 'delivering',
  Arrived = 'arrived',
  Finished = 'delivered',
  Paused = 'paused',
  Canceled = 'canceled',
  Returned = 'returned',
}

export const AllOrdersStatusesList = [
  { id: OrderStatus.Waiting, name: 'Ожидает оплаты' },
  { id: OrderStatus.Received, name: 'Получен' },
  { id: OrderStatus.OnHold, name: 'Приостановлен к отправке' },
  { id: OrderStatus.Sending, name: 'Отправляется Партнеру' },
  { id: OrderStatus.Confirmed, name: 'Принят партнером' },
  { id: OrderStatus.Preparing, name: 'Готовится' },
  { id: OrderStatus.Ready, name: 'Готов' },
  { id: OrderStatus.Sent, name: 'Отправлен в доставку' },
  { id: OrderStatus.Assigned, name: 'Назначен курьеру' },
  { id: OrderStatus.Delivering, name: 'Доставляется' },
  { id: OrderStatus.Arrived, name: 'На адресе' },
  { id: OrderStatus.Finished, name: 'Завершен' },
  { id: OrderStatus.Paused, name: 'Приостановлен при доставке' },
  { id: OrderStatus.Canceled, name: 'Отменен' },
  { id: OrderStatus.Returned, name: 'Возвращен' },
]

/** BOTH ORDERS **/
export const OrderStatusesActive = [OrderStatus.Received, OrderStatus.Sending]
export const OrderStatusesFinished = [
  OrderStatus.Paused,
  OrderStatus.Canceled,
  OrderStatus.Returned,
  OrderStatus.Finished,
]

/** ORDER DELIVERY **/
const deliveryOrderFinishedStatuses = [
  OrderStatus.Waiting,
  OrderStatus.Received,
  OrderStatus.OnHold,
  OrderStatus.Sending,
  OrderStatus.Confirmed,
  OrderStatus.Preparing,
  OrderStatus.Ready,
  OrderStatus.Sent,
  OrderStatus.Assigned,
  OrderStatus.Delivering,
  OrderStatus.Arrived,
  OrderStatus.Finished,
]

export const DeliveryOrderStatusFinishedList = _.filter(AllOrdersStatusesList, (el) =>
  deliveryOrderFinishedStatuses.includes(el.id),
)

const deliveryOrderUnfinishedStatuses = [OrderStatus.Paused, OrderStatus.Canceled, OrderStatus.Returned]

export const DeliveryOrderStatusUnfinishedList = _.filter(AllOrdersStatusesList, (el) =>
  deliveryOrderUnfinishedStatuses.includes(el.id),
)

export const DeliveryOrderStatusList = [...DeliveryOrderStatusFinishedList, ...DeliveryOrderStatusUnfinishedList]

export const DeliveryOrderStatusesDidntSentForDelivery = [
  OrderStatus.Waiting,
  OrderStatus.Received,
  OrderStatus.OnHold,
  OrderStatus.Sending,
  OrderStatus.Confirmed,
  OrderStatus.Preparing,
  OrderStatus.Ready,
  OrderStatus.Sent,
]

/** Takeaway Order **/
const takeawayOrderFinishedStatuses = [
  OrderStatus.Waiting,
  OrderStatus.Received,
  OrderStatus.OnHold,
  OrderStatus.Sending,
  OrderStatus.Confirmed,
  OrderStatus.Preparing,
  OrderStatus.Ready,
  OrderStatus.Finished,
]

export const TakeawayOrderStatusFinishedList = _.filter(AllOrdersStatusesList, (el) =>
  takeawayOrderFinishedStatuses.includes(el.id),
)

const takeawayOrderUnfinishedStatuses = [OrderStatus.Canceled, OrderStatus.Returned]

export const TakeawayOrderStatusUnfinishedList = _.filter(AllOrdersStatusesList, (el) =>
  takeawayOrderUnfinishedStatuses.includes(el.id),
)

export const TakeawayOrderStatusList = [...TakeawayOrderStatusFinishedList, ...TakeawayOrderStatusUnfinishedList]
