import * as React from 'react'
import { FC } from 'react'
import {
  Filter,
  FunctionField,
  List,
  ListProps,
  NumberField,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  useGetIdentity,
  useResourceContext,
} from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { Datagrid } from 'src/components/list/Datagrid'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { UnitStatusList } from 'src/types/enum/UnitStatus'
import UnitStatusField from 'src/components/fields/UnitStatusField'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'
import { AggregatorAdminRoles } from '../../types/enum/AdminRole'
import RegionInput from '../../components/inputs/ReqionInput'
import CountryArrayInput from '../../components/inputs/CountryArrayInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Unit',
  })
}

const _Filter = (props: any) => {
  const { data: user } = useGetIdentity()

  return (
    <Filter {...props}>
      <TextInput source="name:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn resettable />
      <SelectInput source="status" label={'Статус'} choices={UnitStatusList} />
      <IfCanAccess source={'partnerId'} label={'Партнер'} aggregator action={'show'}>
        <PartnerInput source={'partnerId'} label={'Партнер'} />
      </IfCanAccess>
      <CountryArrayInput source={'countryId:in'} label={'Страны'} fullWidth />
      <IfCanAccess source={'region'} label={'Регион'} aggregator action={'list'}>
        <RegionInput source={'regionId'} label={'Регион'} />
      </IfCanAccess>
      {AggregatorAdminRoles.includes(user?.role) && <NumberInput source="sort" label={'Приоритет'} />}
    </Filter>
  )
}

const RestaurantList: FC<ListProps> = (props) => {
  const resource = useResourceContext()
  const { data: user } = useGetIdentity()

  return (
    <List
      {...props}
      filters={<_Filter />}
      title={ServiceTypeDictionary.getServiceEntity(resource)?.multi}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <FunctionField source={'innerName'} label={'Название'} render={(order: any) => order.innerName ?? order.name} />
        <TextField source={'address'} label={'Адрес'} />
        <TextField source={'region.name'} label={'Регион'} />
        <IfCanAccess aggregator action={'show'} label={'Партнер'}>
          <TextField source="partner.name" label={'Партнер'} />
        </IfCanAccess>
        {AggregatorAdminRoles.includes(user?.role) && <NumberField source="sort" label={'Приоритет'} />}
        <UnitStatusField source={'status'} label={'Статус'} />
      </Datagrid>
    </List>
  )
}

export default RestaurantList
