import * as React from 'react'
import { SimpleForm, SelectInput, TextInput, BooleanInput, required } from 'react-admin'
import { ViewTemplateType, ViewTemplateTypeList } from 'src/types/enum/ViewTemplateType'
import { ViewTemplateItemList } from 'src/resources/view-template/view-template-item/ViewTemplateItemList'
import { ServiceTypeList } from 'src/types/enum/ServiceType'
import CountryInput from '../../components/inputs/CountryInput'

const Form = () => {
  return (
    <>
      <TextInput source={'name'} label={'Название'} validate={[required()]} />
      <CountryInput source="countryId" label={'Страна'} validate={[required()]} fullWidth />
      <SelectInput source="serviceType" label={'Сервис'} choices={ServiceTypeList} />
      <SelectInput source={'type'} label={'Тип'} choices={ViewTemplateTypeList} />
      <BooleanInput source={'isDefault'} label={'По умолчанию'} />
    </>
  )
}
const ViewTemplateForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ type: ViewTemplateType.Main }}>
      <Form />
      {props.isEdit && <ViewTemplateItemList />}
    </SimpleForm>
  )
}
export default ViewTemplateForm
