import * as React from 'react'
import { TextInput, required, SimpleForm, BooleanInput, ArrayInput, SimpleFormIterator } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import Formatter from 'src/utils/formatters'
import TimeZoneSelectInput from 'src/components/inputs/TimeZoneSelectInput'
import CountryInput from 'src/components/inputs/CountryInput'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return (
    <>
      <TextInput source="name" label={'Название'} validate={[required()]} fullWidth onChange={handleNameChange} />
      <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
      <CountryInput source={'countryId'} label={'Страна'} fullWidth validate={required()} />
      <TextInput
        source="location"
        label={'Координаты Центра'}
        helperText={'Через запуятую, например 55.616351,36.431444'}
        parse={(value) => {
          const parts = value.split(',')
          if (parts.length < 2 || !parts[0] || !parts[1]) {
            return value
          }
          return { lat: parts[0].trim(), lng: parts[1].trim() }
        }}
        format={(value) => {
          if (value?.lat && value.lng) {
            return `${value.lat},${value.lng}`
          }
          return value
        }}
        multiline={true}
        variant={'outlined'}
        fullWidth={true}
      />
      <TimeZoneSelectInput source={'timezone'} label={'Часовой пояс'} fullWidth validate={[required()]} />
      <ArrayInput source="bounds" label={'Границы региона, формат bounds'}>
        <SimpleFormIterator inline>
          <TextInput source="." />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="published" label={'Активен'} fullWidth />
      <BooleanInput source="isDefault" label={'По умолчанию'} fullWidth />
      <BooleanInput source="hasDelivery" label={'Есть доставка'} fullWidth />
      <BooleanInput source="hasLocal" label={'Есть локальные рестораны'} fullWidth />
    </>
  )
}
const RegionForm = (props: any) => {
  return (
    <SimpleForm {...props} defaultValues={{ published: true }} redirect={'list'}>
      <InfoTab />
    </SimpleForm>
  )
}
export default RegionForm
