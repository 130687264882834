import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import PartnerInput from 'src/components/inputs/PartnerInput'
import CountryInput from '../../components/inputs/CountryInput'

const _Filter = (props: any) => (
  <Filter {...props}>
    <CountryInput source={'partner.countryId'} label={'Страна'} alwaysOn resettable />
    <PartnerInput source="partnerId" label={'Партнер'} alwaysOn resettable />
  </Filter>
)

const PartnerOfferList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Индивидуальные условия"
      empty={
        <EmptyList
          title={'Нет индивидуальных условий'}
          description={'Вы можете добавить индивидуальное условие'}
          buttonText={'Добавить индивидиульное условие'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <PartnerLinkField source="partner.id" mainSource={'partner'} label={'Партнер'} />
        <DateField source="offerDate" label={'Дата договора'} showTime={true} />
        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default PartnerOfferList
