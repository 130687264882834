import * as React from 'react'
import {
  RecordContextProvider,
  useDataProvider,
  useRefresh,
  FileInput,
  FileField,
  required,
  NumberInput,
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { PromoKind } from 'src/types/enum/PromoKind'
import PromoServiceDeliveryInput from 'src/components/inputs/PromoServiceDeliveryInput'

interface Props {
  isShown?: boolean
  id?: number
  onClose?: () => void
  isTakeaway: boolean
}

export const PromoCodeImportModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.create(props.isTakeaway ? 'promo-code-takeaway/import' : 'promo-code/import', {
      data: { ...data },
    })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'area'}
        title={'Импорт промокодов доставки из файла'}
        onClose={props.onClose}
        save={handleSubmit}
      >
        <FileInput
          source="file"
          label={'Файл импорта'}
          helperText={'Поддерживаемый формат KML. Импортируется только Polygon'}
          multiple={false}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <PromoServiceDeliveryInput
          resettable={true}
          source="promoId"
          label={'Акция'}
          filter={{ kind: PromoKind.Personal }}
          isTakeaway={props.isTakeaway}
          fullWidth
          validate={[required()]}
        />
        <NumberInput source="perCustomerUsageLimit" label={'Лимит для одного клиента'} helperText={''} fullWidth />
        <NumberInput source="usageLimit" label={'Общий лимит на промокод'} helperText={''} fullWidth />
      </ModalForm>
    </RecordContextProvider>
  )
}
