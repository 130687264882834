import * as React from 'react'
import { TabbedForm, FormTab, SelectInput, FormDataConsumer } from 'react-admin'
import { DeliveryOrderStatusList, TakeawayOrderStatusList } from 'src/types/enum/OrderStatus'
import { PaymentStatusList } from 'src/types/enum/PaymentStatus'
import { PaymentMethodList } from 'src/types/enum/PaymentMethod'
import { DeliveryMethod, DeliveryMethodList } from 'src/types/enum/DeliveryMethod'
import { useRecordContext } from 'ra-core'
import { useMemo } from 'react'
import { OrderType } from '../../types/enum/OrderType'

const InfoTab = (props: any) => {
  const record = useRecordContext()
  //
  const isTakeaway = useMemo(() => record.type === OrderType.Takeaway, [record])
  //
  return (
    <FormTab label="Заказ" path={'benefit'} {...props}>
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.deliveryMethod !== record.deliveryMethod) {
            return (
              <SelectInput
                source="status"
                label={'Статус заказа'}
                choices={
                  formData.deliveryMethod === DeliveryMethod.Pickup ? TakeawayOrderStatusList : DeliveryOrderStatusList
                }
                fullWidth
              />
            )
          }
          return (
            <SelectInput
              source="status"
              label={'Статус заказа'}
              choices={isTakeaway ? TakeawayOrderStatusList : DeliveryOrderStatusList}
              fullWidth
            />
          )
        }}
      </FormDataConsumer>
      <SelectInput source="paymentStatus" label={'Статус оплаты'} choices={PaymentStatusList} fullWidth />
      <SelectInput source="paymentMethod" label={'Способ оплаты'} choices={PaymentMethodList} fullWidth />
      <SelectInput source="deliveryMethod" label={'Способ доставки'} choices={DeliveryMethodList} fullWidth />
    </FormTab>
  )
}
const OrderForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab />
    </TabbedForm>
  )
}
export default OrderForm
