import { INode } from './types'
import { Project } from '../../../types/enum/Project'

export function dataObjToArr(data: any, ids: any[]): any[] {
  const arr: any[] = []
  ids.forEach((id) => {
    const row = data[id]
    if (row) {
      arr.push(row)
    }
  })
  return arr
}

export function listToTree(data: any[]): INode[] {
  const map: any = {}
  const roots: any[] = []
  const list: any[] = []
  let i
  let node
  for (i = 0; i < data.length; ++i) {
    map[data[i].id] = i // initialize the map
    list[i] = { ...data[i] }
    list[i].children = [] // initialize the children
  }
  for (i = 0; i < list.length; ++i) {
    node = list[i]
    if (node.parentId && list[map[node.parentId]]) {
      // if you have dangling branches check that map[node.parent_id] exists
      list[map[node.parentId]].children.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}

export function isDandbProject(): boolean {
  return process.env.REACT_APP_NODE_PROJECT === Project.Dandb
}

export function getCountryIdFilterBasedOnProject(countryId?: string | null): object {
  if (process.env.REACT_APP_NODE_PROJECT === Project.Dandb) {
    return { countryId: countryId || '0' }
  }
  return {}
}
