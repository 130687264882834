import { AutocompleteInput, FilterPayload, ReferenceInput } from 'react-admin'
import * as React from 'react'
import * as _ from 'lodash'
import { PromoKindList } from 'src/types/enum/PromoKind'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  fullWidth?: boolean
  filter?: FilterPayload
  helperText?: string
  multiple?: boolean
  validate?: any
  isTakeaway?: boolean
}
const PromoServiceDeliveryInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source={props.source ?? 'promoId'}
      reference={props.isTakeaway ? 'promo-service-takeaway' : 'promo-service-delivery'}
      variant={'outlined'}
      filter={_.assign({ published: true }, _.isPlainObject(props.filter) ? props.filter : {})}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      {...(_.omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        optionText={(i) => `${PromoKindList.find((el) => el.id === i?.kind)?.name}: ${i?.innerName || i?.name || ''}`}
        label="Акция"
        disabled={props.disabled}
        multiple={props.multiple}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        validate={props.validate}
      />
    </ReferenceInput>
  )
}

export default PromoServiceDeliveryInput
