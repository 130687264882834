import * as React from 'react'
import { useRecordContext } from 'ra-core'
import { FieldProps, RaRecord, useNotify } from 'react-admin'
import get from 'lodash/get'
import Formatter from 'src/utils/formatters'
import { MouseEventHandler, useState } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import CallIcon from '@mui/icons-material/Call'
import IconButton from '@mui/material/IconButton'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import LinkField from 'src/components/fields/LinkField'
import { httpClient } from 'src/common/providers/dataProvider'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  link?: string
  onlyIcon?: boolean
  phone?: string
}

const PhoneCallActionField = (props: Props) => {
  const record = useRecordContext(props)
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const value = props.phone ?? get(record, props.source ?? '')
  const handleClick: MouseEventHandler = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    setLoading(true)
    try {
      const res = await httpClient(`${process.env.REACT_APP_API_URL || ''}/admin/api/telephony/make-call`, {
        method: 'POST',
        body: JSON.stringify({ phone: value }),
      })
      notify('Звонок выполняется', { type: 'success' })
    } catch (e: any) {
      notify(e.message.toString(), { type: 'error' })
    }
    setLoading(false)
  }
  if (!value) {
    return null
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IfCanAccess aggregator action={'show'}>
        <IconButton
          type={'button'}
          size={'small'}
          color={'primary'}
          disabled={loading}
          onClick={handleClick}
          sx={{ marginRight: '5px' }}
        >
          {loading ? <CircularProgress color="primary" size={24} thickness={5} /> : <CallIcon />}
        </IconButton>
      </IfCanAccess>

      {!props.onlyIcon && !props.link && (
        <Typography variant={'body2'} sx={{ whiteSpace: 'nowrap' }}>
          {Formatter.formatPhone(value)}
        </Typography>
      )}
      {!props.onlyIcon && props.link && (
        <LinkField
          link={props.link}
          source={props.source}
          value={Formatter.formatPhone(value)}
          label={props.label}
          style={{ whiteSpace: 'nowrap' }}
        />
      )}
    </div>
  )
}

export default PhoneCallActionField
