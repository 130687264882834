import * as React from 'react'
import {
  SimpleForm,
  ArrayInput,
  TextInput,
  RadioButtonGroupInput,
  SimpleFormIterator,
  required,
  BooleanInput,
  useResourceContext,
} from 'react-admin'
import { ArticleType, ArticleTypeList } from 'src/types/enum/ArticleType'
import LanguageInput from 'src/components/inputs/LanguageInput'
import CountryInput from '../../components/inputs/CountryInput'

const Form = () => {
  return (
    <>
      <BooleanInput source="published" label={'Опубликовано'} />
      <RadioButtonGroupInput
        source={'categoryType'}
        label={'Тип'}
        choices={ArticleTypeList}
        validate={required()}
        fullWidth={true}
      />
      <CountryInput source="countryId" label={'Страна'} validate={[required()]} fullWidth />
      <TextInput source="slug" label={'URI'} fullWidth validate={required()} />
      <ArrayInput source="translations" label={'Контент'} fullWidth>
        <SimpleFormIterator fullWidth>
          <LanguageInput source="languageCode" label={'Язык'} validate={required()} />
          <TextInput source="name" label={'Название'} fullWidth validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

const ArticleCategoryForm = (props: any) => {
  const resource = useResourceContext()
  const getDefaultType = () => {
    switch (resource) {
      case 'article-category':
        return ArticleType.Article
    }
  }
  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      defaultValues={{ published: true, categoryType: getDefaultType(), translations: [{ languageCode: 'ru' }] }}
    >
      <Form />
    </SimpleForm>
  )
}

export default ArticleCategoryForm
