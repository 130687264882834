import * as React from 'react'
import { FC, useMemo } from 'react'
import { Filter, List, TopToolbar, ListProps, TextField, TextInput, CreateButton } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { ModalButton } from 'src/components/Modal/ModalButton'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { PromoCodeImportModal } from 'src/resources/promo-code/PromoCodeImportModal'
import { PromoCodeGenerateModal } from 'src/resources/promo-code/PromoCodeGenerateModal'
import { Datagrid } from 'src/components/list/Datagrid'
import CountryInput from '../../components/inputs/CountryInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'PromoCode',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="code:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn resettable />
      <CountryInput source={'countryId'} label={'Страна'} alwaysOn resettable />
    </Filter>
  )
}

const PromoCodeList: FC<ListProps> = (props) => {
  const isTakeaway = useMemo(() => window.location.href.includes('-takeaway'), [window.location.href])
  //
  const generateBtn = (
    <ModalButton
      label={'Генерация'}
      icon={<UploadFileIcon />}
      key={'generate'}
      modal={<PromoCodeGenerateModal isTakeaway={isTakeaway} />}
    />
  )
  const importBtn = (
    <ModalButton
      label={'Импорт'}
      icon={<UploadFileIcon />}
      key={'import'}
      modal={<PromoCodeImportModal isTakeaway={isTakeaway} />}
    />
  )

  return (
    <List
      {...props}
      filters={<_Filter />}
      actions={
        <TopToolbar>
          {generateBtn}
          {importBtn}
          <CreateButton />
        </TopToolbar>
      }
      title="Промокоды"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Пока нет промокодов'} actions={[<CreateButton />, generateBtn, importBtn]} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'code'} label={'Промокод'} />
        <TextField source={'promo.name'} label={'Акция'} />
        <TextField source={'perCustomerUsageLimit'} label={'Лимит клиента'} />
        <TextField source={'usageLimit'} label={'Лимит'} />
      </Datagrid>
    </List>
  )
}

export default PromoCodeList
