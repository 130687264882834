import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, SelectField } from 'react-admin'
import { csvExporter, formatCsvDate, formatListValue } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { PartnerCommissionTypeList } from 'src/types/enum/PartnerCommissionType'
import { Datagrid } from 'src/components/list/Datagrid'
import { PromoUnitStatusList } from 'src/types/enum/PromoUnitStatus'
import CountryInput from '../../components/inputs/CountryInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      name: 'Название',
    },
    data: posts.map((item: any) => ({
      ...item,
      commissionType: formatListValue(item.commissionType, PartnerCommissionTypeList),
      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'PromoUnitGroup',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <CountryInput source="promo.countryId" label={'Страна'} resettable />
    </Filter>
  )
}

const PromoUnitGroupList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Группы акций заведения"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет групп'} description={'Вы можете добавить новую группу'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'name'} label={'Название группы'} />
        <TextField source={'promo.name'} label={'Акция'} />
        <SelectField source={'status'} label={'Статус'} choices={PromoUnitStatusList} />
        <DateField source={'createdAt'} label={'Создана'} showTime />
        <DateField source={'updatedAt'} label={'Обновлена'} showTime />
      </Datagrid>
    </List>
  )
}

export default PromoUnitGroupList
