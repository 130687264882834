import * as React from 'react'
import { FC } from 'react'
import { Filter, List, SelectInput, ListProps, TextField, SelectField, TextInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { PromoUnitStatusList } from 'src/types/enum/PromoUnitStatus'
import PromoServiceDeliveryInput from 'src/components/inputs/PromoServiceDeliveryInput'
import { Datagrid } from 'src/components/list/Datagrid'
import UnitLinkField from 'src/components/fields/UnitLinkField'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import BrandArrayInput from '../../components/inputs/BrandArrayInput'
import UnitArrayInput from '../../components/inputs/UnitArrayInput'
import PromoUnitGroupArrayInput from '../../components/inputs/PromoUnitGroupArrayInput'
import CountryInput from '../../components/inputs/CountryInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'PromoUnit',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput
        source="promo.name:$contL"
        label={'Поиск по акции...'}
        variant={'outlined'}
        alwaysOn={true}
        resettable={true}
      />
      <SelectInput source={'status'} label={'Статус'} choices={PromoUnitStatusList} resettable={true} />
      <PartnerInput resettable source="partnerId" label={'Партнер'} />
      <BrandArrayInput source={'unit.brandId:$in'} label={'Бренды'} displayPartner />
      <CountryInput source="unit.countryId" label={'Страна'} resettable />
      <UnitArrayInput alwaysOn source="unitId:$in" label={'Заведения'} />
      <PromoUnitGroupArrayInput source="groupId:$in" label={'Группы акций заведения'} />
      <PromoServiceDeliveryInput resettable source="promoId" label={'Акция'} />
    </Filter>
  )
}

const PromoUnitList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Акции заведений"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'promo.name'} label={'Акция'} />
        <UnitLinkField source={'unit'} label={'Заведение'} />
        <TextField source={'unit.brand.name'} label={'Бренд'} />
        <TextField source={'group.name'} label={'Группа'} />
        <SelectField source={'status'} label={'Статус'} choices={PromoUnitStatusList} />
        <PartnerLinkField source={'partner'} label={'Партнер'} />
      </Datagrid>
    </List>
  )
}

export default PromoUnitList
