import * as React from 'react'
import { FC } from 'react'
import {
  Filter,
  List,
  SelectInput,
  ListProps,
  TextField,
  SelectField,
  useResourceContext,
  TextInput,
  NumberField,
} from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { PromoUnitStatusList } from 'src/types/enum/PromoUnitStatus'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'
import PromoServiceRestaurantsInput from 'src/components/inputs/PromoServiceRestaurantsInput'
import { Datagrid } from 'src/components/list/Datagrid'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import RegionInput from '../../components/inputs/ReqionInput'
import { IfCanAccess } from '../../components/permissions/IfCanAccess'
import { PromotionTypeList } from '../../types/enum/PromotionType'
import { useRecordContext } from 'ra-core'
import CountryArrayInput from '../../components/inputs/CountryArrayInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'PromoRestaurant',
  })
}

const _Filter = (props: any) => {
  const resource = useResourceContext()

  return (
    <Filter {...props}>
      <TextInput
        source="promo.name:$contL"
        label={'Поиск по акции...'}
        variant={'outlined'}
        alwaysOn={true}
        resettable={true}
      />
      <RestaurantInput
        serviceType={ServiceTypeDictionary.convertResource(resource)}
        alwaysOn={true}
        resettable={true}
        source="restaurantId"
        label={'Ресторан'}
      />
      <SelectInput source={'status'} label={'Статус'} choices={PromoUnitStatusList} resettable />
      <SelectInput source={'promotionType'} label={'Тип акции'} choices={PromotionTypeList} resettable />
      <PartnerInput source="partnerId" label={'Партнер'} resettable />
      <CountryArrayInput source={'restaurant.countryId:$in'} label={'Страны'} />
      <IfCanAccess source={'region'} label={'Регион'} action={'list'}>
        <RegionInput source="restaurant.regionId" label={'Регион'} resettable />
      </IfCanAccess>
      <PromoServiceRestaurantsInput source="promoId" label={'Акция'} resettable />
    </Filter>
  )
}

const PriorityField = (props: any) => {
  const record = useRecordContext()

  if (record.priority === 0) {
    return null
  }
  return <NumberField source={'priority'} />
}

const PromoRestaurantList: FC<ListProps> = (props) => {
  const resource = useResourceContext()

  return (
    <List
      {...props}
      filters={<_Filter />}
      title={ServiceTypeDictionary.getPromoRestaurant(resource)?.multi}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'promo.name'} label={'Акция'} />
        <RestaurantLinkField source={'restaurant.id'} label={'Ресторан'} />
        <SelectField source={'status'} label={'Статус'} choices={PromoUnitStatusList} />
        <SelectField source={'promotionType'} label={'Тип акции'} choices={PromotionTypeList} />
        <PriorityField label={'Приоритет'} />
        <PartnerLinkField source={'partner'} label={'Партнер'} />
      </Datagrid>
    </List>
  )
}

export default PromoRestaurantList
