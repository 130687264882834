import { createContext, useContext, useState, useRef, ReactElement, useEffect } from 'react'
import { useDataProvider, useGetIdentity } from 'react-admin'
import { ComplaintStatus } from 'src/types/enum/ComplaintStatus'
import { useRecursiveTimeout } from 'src/components/hooks/useRecursiveTimeout'
import { AdminRole, AggregatorAdminRoles } from 'src/types/enum/AdminRole'

interface IState {
  complaintsCreated: number
  restaurantPromoReserveConfirmation: number
  restaurantPromoReserveExpired: number
  fetchPromoReserve: () => void
  fetchComplaint: () => void
}

const defaultValue: IState = {
  complaintsCreated: 0,
  restaurantPromoReserveConfirmation: 0,
  restaurantPromoReserveExpired: 0,
  fetchPromoReserve: () => null,
  fetchComplaint: () => null,
}

const TotalContext = createContext<IState>(defaultValue)

interface Props {
  children: ReactElement
}

export function TotalWrapper(props: Props) {
  const { data } = useGetIdentity()
  const [complaintsCreated, setComplaintsCreated] = useState<number>(0)
  const [restaurantPromoReserveConfirmation, setRestaurantPromoReserveConfirmation] = useState<number>(0)
  const [restaurantPromoReserveExpired, setRestaurantPromoReserveExpired] = useState<number>(0)
  const dataProvider = useDataProvider()

  const isAggregator = useRef<boolean>(AggregatorAdminRoles.includes(data?.role as AdminRole))
  useEffect(() => {
    isAggregator.current = AggregatorAdminRoles.includes(data?.role as AdminRole)
    fetchComplaint()
    fetchPromoReserve()
  }, [data?.role])
  const fetchComplaint = async () => {
    if (!isAggregator.current) {
      return
    }
    try {
      const res = await dataProvider.getList('complaint/total', {
        pagination: { page: 1, perPage: 54 },
        sort: { field: 'id', order: 'DESC' },
        filter: { status: `${ComplaintStatus.Created}` },
      })
      setComplaintsCreated(res.total ?? 0)
    } catch (e) {
      console.error(e)
    }
  }
  const fetchPromoReserve = async () => {
    if (!isAggregator.current) {
      return
    }
    try {
      const res = await dataProvider.getList<{
        id: any
        totalRestaurantPromoReserveConfirmation: number
        totalRestaurantPromoReserveExpired: number
      }>('promo-reserve-confirmation/total-to-confirm', {
        pagination: { page: 1, perPage: 54 },
        sort: { field: 'id', order: 'DESC' },
        filter: {},
      })
      setRestaurantPromoReserveConfirmation(res.data[0]!.totalRestaurantPromoReserveConfirmation ?? 0)
      setRestaurantPromoReserveExpired(res.data[0]!.totalRestaurantPromoReserveExpired ?? 0)
    } catch (e) {
      console.error(e)
    }
  }
  useRecursiveTimeout(() => fetchComplaint(), 30 * 1000)
  useRecursiveTimeout(() => fetchPromoReserve(), 30 * 1000)

  const value: IState = {
    ...defaultValue,
    complaintsCreated,
    restaurantPromoReserveConfirmation,
    restaurantPromoReserveExpired,
    fetchPromoReserve,
    fetchComplaint,
  }
  return <TotalContext.Provider value={value}>{props.children}</TotalContext.Provider>
}

export function useTotalContext() {
  return useContext(TotalContext)
}
